// extracted by mini-css-extract-plugin
export var arrowIcon = "player-merge-modal-module--arrowIcon--cDHzb";
export var bodyBase = "player-merge-modal-module--body-base--8BEXy player-merge-modal-module--site-font--jSOH8";
export var bodyLarge = "player-merge-modal-module--body-large--ouPRJ player-merge-modal-module--body-base--8BEXy player-merge-modal-module--site-font--jSOH8";
export var bodyLargeBold = "player-merge-modal-module--body-large-bold--GD3XU player-merge-modal-module--body-base--8BEXy player-merge-modal-module--site-font--jSOH8";
export var bodyRegular = "player-merge-modal-module--body-regular--Urho0 player-merge-modal-module--body-base--8BEXy player-merge-modal-module--site-font--jSOH8";
export var bodyRegularBold = "player-merge-modal-module--body-regular-bold--bflGi player-merge-modal-module--body-base--8BEXy player-merge-modal-module--site-font--jSOH8";
export var bodySmall = "player-merge-modal-module--body-small--mSNVh player-merge-modal-module--body-base--8BEXy player-merge-modal-module--site-font--jSOH8";
export var bodySmallBold = "player-merge-modal-module--body-small-bold--NN3hZ player-merge-modal-module--body-base--8BEXy player-merge-modal-module--site-font--jSOH8";
export var borderTop = "player-merge-modal-module--border-top--4CfyB";
export var breakWordContainer = "player-merge-modal-module--break-word-container--xwgrd";
export var btnCancel = "player-merge-modal-module--btnCancel--ohuQL";
export var btnConfirm = "player-merge-modal-module--btnConfirm--hZMoy";
export var buttonIconBase = "player-merge-modal-module--button-icon-base--NXRnR";
export var cardsContainer = "player-merge-modal-module--cardsContainer--jQfLg";
export var checkbox = "player-merge-modal-module--checkbox--Ghd1m";
export var checkboxLabel = "player-merge-modal-module--checkboxLabel--sPoyb";
export var checkboxRoot = "player-merge-modal-module--checkboxRoot--SBPL6";
export var clickLink = "player-merge-modal-module--click-link--E3l7K";
export var dropdownBase = "player-merge-modal-module--dropdown-base--z7PeD";
export var dropdownSelectBase = "player-merge-modal-module--dropdown-select-base--sf9L- player-merge-modal-module--text-input--k1GRS";
export var flexCol = "player-merge-modal-module--flex-col--QQcCv";
export var formErrorMessage = "player-merge-modal-module--form-error-message--kzOk7";
export var h3 = "player-merge-modal-module--h3--JNPGF";
export var h4 = "player-merge-modal-module--h4--F6+i8";
export var hoverLink = "player-merge-modal-module--hover-link--qIBub";
export var hoverRow = "player-merge-modal-module--hover-row--jnYp5";
export var membershipContainer = "player-merge-modal-module--membership-container--iwhHS player-merge-modal-module--flex-col--QQcCv player-merge-modal-module--primary-border--U5YsV";
export var membershipHeader = "player-merge-modal-module--membership-header--PIpxp";
export var membershipHeading = "player-merge-modal-module--membership-heading--WEO9+";
export var membershipLabel = "player-merge-modal-module--membership-label--d0XUJ";
export var moreFiltersBorderClass = "player-merge-modal-module--more-filters-border-class--t47n2";
export var pageBg = "player-merge-modal-module--page-bg--1noIF";
export var pointer = "player-merge-modal-module--pointer--KITfM";
export var primaryBorder = "player-merge-modal-module--primary-border--U5YsV";
export var shadowBoxLight = "player-merge-modal-module--shadow-box-light--+ftbR";
export var siteFont = "player-merge-modal-module--site-font--jSOH8";
export var slideDownAndFade = "player-merge-modal-module--slideDownAndFade--hFwr7";
export var slideLeftAndFade = "player-merge-modal-module--slideLeftAndFade--zoepI";
export var slideRightAndFade = "player-merge-modal-module--slideRightAndFade--sEJ4X";
export var slideUpAndFade = "player-merge-modal-module--slideUpAndFade--KU4Cf";
export var statusDecoration = "player-merge-modal-module--status-decoration--p7a3c";
export var textInput = "player-merge-modal-module--text-input--k1GRS";
export var textInverted = "player-merge-modal-module--text-inverted--95ioD";
export var textMediumDark = "player-merge-modal-module--text-medium-dark--kThln";
export var tooltipFont = "player-merge-modal-module--tooltipFont--9i-Q6";
export var unstyledButton = "player-merge-modal-module--unstyled-button--mZcna";