import React, { useState } from 'react'
import * as styles from './player-merge-modal.module.less'
import ButtonModal from '../modal/modal'
import { useTranslation } from 'react-i18next'
import { Body } from '../typography/typography'
import { PropsMini, RecordTransferCardMini } from '../record-transfer-card/record-transfer-card'
import ArrowDown from '../../images/icons/24px/arrow-down-grey.svg'
import { Checkbox } from '@clubspark-react/ui'
import Alert from '../alert/alert'

type TransferCardProps = Omit<PropsMini, 'recordType' | 'hideLabel'>

export interface Props {
  isVisible: boolean
  setIsVisible: (isVisible: boolean) => void
  movingTransferCardProps: TransferCardProps
  destinationTransferCardProps: TransferCardProps
  confirmCheckboxText?: string
  onConfirm: () => void
  isMerging: boolean
}

const PlayerMergeModal = ({
  isVisible,
  setIsVisible,
  movingTransferCardProps,
  destinationTransferCardProps,
  confirmCheckboxText,
  isMerging,
  onConfirm
}: Props) => {
  const [isChecked, setIsChecked] = useState(false)
  const { t } = useTranslation()
  return (
    <ButtonModal
      title={t('mergePlayers.modal.header')}
      openButton={{ hidden: true }}
      hideCloseButton
      actionButtons={[
        {
          id: 'cancel',
          content: t('cancel'),
          props: {
            level: 'tertiary',
            onClick: () => setIsVisible(false),
            className: styles.btnCancel,
            disabled: isMerging
          }
        },
        {
          id: 'confirmMerge',
          content: t('mergePlayers.modal.confirmBtn'),
          props: {
            type: 'submit',
            level: 'primary',
            onClick: onConfirm,
            loading: isMerging,
            disabled: !isChecked,
            className: styles.btnConfirm
          }
        }
      ]}
      show={isVisible}
      showBehaviour="unmount"
    >
      <Body size="lg" spacing={{ base: 4, margins: { lg: 'bottom' } }}>
        {t('mergePlayers.modal.info')}
      </Body>
      <Alert variant="warning" spacing={{ margins: { md: 'right' } }}>
        {t('alerts.irreversibleAction')}
      </Alert>
      <section className={styles.cardsContainer}>
        <RecordTransferCardMini recordType="moving" {...movingTransferCardProps} />
        <ArrowDown className={styles.arrowIcon} aria-hidden />
        <RecordTransferCardMini recordType="destination" {...destinationTransferCardProps} />
      </section>
      <Checkbox
        id="confirm-merge"
        label={confirmCheckboxText || t('mergePlayers.modal.confirmCheckboxGeneric')}
        onChange={val => setIsChecked(val)}
        value={''}
        classNames={{
          checkbox: styles.checkbox,
          label: styles.checkboxLabel,
          root: styles.checkboxRoot
        }}
        props={{ label: { htmlFor: 'confirm-merge' } }}
      />
    </ButtonModal>
  )
}

export default PlayerMergeModal
