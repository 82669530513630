// extracted by mini-css-extract-plugin
export var bodyBase = "record-transfer-card-module--body-base--GNlxo record-transfer-card-module--site-font--evCnM";
export var bodyLarge = "record-transfer-card-module--body-large--GyHTX record-transfer-card-module--body-base--GNlxo record-transfer-card-module--site-font--evCnM";
export var bodyLargeBold = "record-transfer-card-module--body-large-bold--YvY-M record-transfer-card-module--body-base--GNlxo record-transfer-card-module--site-font--evCnM";
export var bodyRegular = "record-transfer-card-module--body-regular--Z5OLn record-transfer-card-module--body-base--GNlxo record-transfer-card-module--site-font--evCnM";
export var bodyRegularBold = "record-transfer-card-module--body-regular-bold--yEVIY record-transfer-card-module--body-base--GNlxo record-transfer-card-module--site-font--evCnM";
export var bodySection = "record-transfer-card-module--bodySection--QeoCN";
export var bodySectionLabel = "record-transfer-card-module--bodySectionLabel--iOsHs";
export var bodySectionValue = "record-transfer-card-module--bodySectionValue--mmZtA";
export var bodySmall = "record-transfer-card-module--body-small--MR777 record-transfer-card-module--body-base--GNlxo record-transfer-card-module--site-font--evCnM";
export var bodySmallBold = "record-transfer-card-module--body-small-bold--KCRkU record-transfer-card-module--body-base--GNlxo record-transfer-card-module--site-font--evCnM";
export var bodyWrapper = "record-transfer-card-module--bodyWrapper--VNBhy";
export var borderTop = "record-transfer-card-module--border-top--adcLj";
export var breakWordContainer = "record-transfer-card-module--break-word-container--wyUkB";
export var buttonIconBase = "record-transfer-card-module--button-icon-base--tXKY+";
export var clickLink = "record-transfer-card-module--click-link--AGyzR";
export var container = "record-transfer-card-module--container--uVBg2";
export var containerMini = "record-transfer-card-module--containerMini--JToI8";
export var detailsWrapperMini = "record-transfer-card-module--detailsWrapperMini--dMfCT";
export var dropdownBase = "record-transfer-card-module--dropdown-base--guBLL";
export var dropdownSelectBase = "record-transfer-card-module--dropdown-select-base--728E6 record-transfer-card-module--text-input--+-g+P";
export var flexCol = "record-transfer-card-module--flex-col--mk-uv";
export var formErrorMessage = "record-transfer-card-module--form-error-message--L70ya";
export var h3 = "record-transfer-card-module--h3--uLwoq";
export var h4 = "record-transfer-card-module--h4--3qvaX";
export var header = "record-transfer-card-module--header--wt410";
export var hoverLink = "record-transfer-card-module--hover-link--j6TSH";
export var hoverRow = "record-transfer-card-module--hover-row--5aA6j";
export var membershipContainer = "record-transfer-card-module--membership-container--11YwO record-transfer-card-module--flex-col--mk-uv record-transfer-card-module--primary-border--u2fJy";
export var membershipHeader = "record-transfer-card-module--membership-header--L9afH";
export var membershipHeading = "record-transfer-card-module--membership-heading--zmNTT";
export var membershipLabel = "record-transfer-card-module--membership-label--gHDxf";
export var mini = "record-transfer-card-module--mini--xM0fn";
export var moreFiltersBorderClass = "record-transfer-card-module--more-filters-border-class--53Kia";
export var moving = "record-transfer-card-module--moving--mjaL+";
export var pageBg = "record-transfer-card-module--page-bg--foXgR";
export var pointer = "record-transfer-card-module--pointer--qjNvG";
export var primaryBorder = "record-transfer-card-module--primary-border--u2fJy";
export var recordTitle = "record-transfer-card-module--recordTitle--i2LXb";
export var shadowBoxLight = "record-transfer-card-module--shadow-box-light--GdN9U";
export var siteFont = "record-transfer-card-module--site-font--evCnM";
export var slideDownAndFade = "record-transfer-card-module--slideDownAndFade--U+Ak0";
export var slideLeftAndFade = "record-transfer-card-module--slideLeftAndFade--ex7G3";
export var slideRightAndFade = "record-transfer-card-module--slideRightAndFade--J685Q";
export var slideUpAndFade = "record-transfer-card-module--slideUpAndFade--lqUGQ";
export var statusDecoration = "record-transfer-card-module--status-decoration--rMFXU";
export var textInput = "record-transfer-card-module--text-input--+-g+P";
export var textInverted = "record-transfer-card-module--text-inverted--KuwYA";
export var textMediumDark = "record-transfer-card-module--text-medium-dark--KNn9H";
export var tooltipFont = "record-transfer-card-module--tooltipFont--a+1+j";
export var topDetail = "record-transfer-card-module--topDetail--vEip7";
export var topDetailsWrapper = "record-transfer-card-module--topDetailsWrapper--2o9O8";
export var type = "record-transfer-card-module--type--hAWrd";
export var unstyledButton = "record-transfer-card-module--unstyled-button--3Vb5k";