import React from 'react'
import { useTranslation } from 'react-i18next'
import { meshGatewayClient } from 'src/apollo/client'
import Layout from 'src/components/layout/layout'
import { withNotifications } from 'src/components/notification-container/notification-container'
import PlayerMerge from 'src/components/player-merge/player-merge'
import { MERGE_PLAYERS, SEARCH_PLAYER_BY_ID } from 'src/queries/ITA/index'
import SEO from 'src/components/seo'
import { SearchPlayerById, SearchPlayerById_player } from 'src/graphql-types/ita/SearchPlayerById'
import { PersonStatusEnum } from 'src/graphql-types/ita/globalITATypes'
import { usePrivateRoute } from 'src/utils/auth'
import { useCountries } from 'src/utils/helper/countries'
import { useDateFormat } from 'src/utils/helper/dates'
import { usePersonGender } from 'src/utils/helper/players'
import ROUTES from 'src/utils/routes'

const ID_LABEL = 'personId'

const mapTransferCardProps = ({ record, helpers: { t } }) => {
  const {
    id,
    playerClass,
    currentTeam,
    dateOfBirth,
    firstName,
    fullName,
    gender,
    lastName,
    matchCount,
    nationality
  } = record
  return {
    recordTitle: fullName,
    headerDetails: [
      t('labelValueFormat.id', { idLabel: ID_LABEL, id }),
      t('labelValueFormat.matchesPlayed', { matchCount })
    ],
    bodyDetails: [
      { label: t('first name'), value: firstName },
      { label: t('last name'), value: lastName },
      {
        label: t('gender'),
        value: gender
      },
      {
        label: t('date of birth'),
        value: dateOfBirth
      },
      { label: t('class'), value: playerClass },
      {
        label: t('nationality'),
        value: nationality
      },
      { label: t('team'), value: currentTeam }
    ]
  }
}

interface Record {
  id: string
  fullName: string
  firstName: string
  lastName: string
  gender: string
  dateOfBirth: string
  nationality: string
  playerClass: string
  currentTeam: string
  matchCount: number
}

const MergePlayersPage = () => {
  usePrivateRoute()
  const { t } = useTranslation()
  const { formatDate } = useDateFormat('MM/DD/YYYY')
  const { getTranslation: getGenderTranslation } = usePersonGender()
  const { getCountryName } = useCountries('en')
  const MISSING_INFO = '-'

  return (
    <Layout>
      <SEO title="merge-players" />
      <PlayerMerge<SearchPlayerById, Record, SearchPlayerById_player>
        searchConfig={{
          query: SEARCH_PLAYER_BY_ID,
          queryOptions: { client: meshGatewayClient, fetchPolicy: 'no-cache' },
          foundText: data => t('ita.mergePlayers.search.found', { id: data?.player?.id }),
          getResultFromData: data =>
            data?.player?.status === PersonStatusEnum.Active ? data.player : null,
          transformDataIntoRecord: data => {
            if (!data?.player) return null

            const {
              id,
              birthDate,
              groups,
              nationalityCode,
              sex,
              standardFamilyName,
              standardGivenName,
              class: playerClass
            } = data.player

            let currentTeam: string | null | undefined
            const currentSeasonId = data?.currentSeason?.id

            if (currentSeasonId)
              currentTeam = groups?.find(g => g.seasonId === currentSeasonId)?.groupName

            return {
              id: id as string,
              fullName: `${standardGivenName} ${standardFamilyName}`,
              firstName: standardGivenName ?? MISSING_INFO,
              lastName: standardFamilyName ?? MISSING_INFO,
              gender: sex ? getGenderTranslation(sex) : MISSING_INFO,
              dateOfBirth: formatDate(birthDate) ?? MISSING_INFO,
              nationality: nationalityCode ? getCountryName(nationalityCode) : MISSING_INFO,
              playerClass: playerClass || MISSING_INFO,
              currentTeam: currentTeam || MISSING_INFO,
              matchCount: data?.matchUps?.totalItems ?? 0
            }
          },
          mapSearchToQueryVariables: search => ({
            id: search
          }),
          placeholderSearch: t('mergePlayers.search.placeholder.personId')
        }}
        transferCardConfig={{
          mapTransferCardProps
        }}
        mergeConfig={{
          mutation: MERGE_PLAYERS,
          mutationOptions: { client: meshGatewayClient },
          mapContextToMutationVariables: ({ records: { moving, destination } }) => ({
            movingRecordId: moving.id,
            destinationRecordId: destination.id
          }),
          mapModalProps: ({ records: { moving, destination }, helpers: { t } }) => {
            const movingCardProps = mapTransferCardProps({ record: moving, helpers: { t } })
            const destinationCardProps = mapTransferCardProps({
              record: destination,
              helpers: { t }
            })
            return {
              confirmCheckboxText: t('mergePlayers.modal.confirmCheckbox', {
                idLabel: ID_LABEL,
                id: moving.id
              }),
              movingTransferCardProps: {
                recordTitle: movingCardProps.recordTitle,
                details: movingCardProps.headerDetails
              },
              destinationTransferCardProps: {
                recordTitle: destinationCardProps.recordTitle,
                details: destinationCardProps.headerDetails
              }
            }
          },
          mergeSuccessText: ({ records: { moving, destination }, helpers: { t } }) =>
            t('mergePlayers.successScreen.info', {
              idLabel: ID_LABEL,
              movingId: moving.id,
              destinationId: destination.id
            }),
          mergeSuccessLinks: ({ helpers: { t } }) => [
            { toUrl: ROUTES.PLAYERS, text: t('mergePlayers.successScreen.btnPlayers') }
          ]
        }}
      />
    </Layout>
  )
}

export default withNotifications(MergePlayersPage)
