import React, { ReactNode } from 'react'
import * as styles from './success-panel.module.less'
import Panel from '../panel/panel'
import { Body, H1 } from '../typography/typography'
import SuccessIcon from '../../images/icons/48px/check-success.svg'

interface Props {
  title: string
  infoText: string | ReactNode
  children?: ReactNode
}

const SuccessPanel = ({ children, title, infoText }: Props) => {
  return (
    <Panel classNames={{ container: styles.container }}>
      <SuccessIcon className={styles.icon} />
      <H1 spacing={{ base: 4, margins: { md: 'bottom', lg: 'top' } }}>{title}</H1>
      {typeof infoText === 'string' ? <Body size={'md'}>{infoText}</Body> : infoText}
      {children}
    </Panel>
  )
}

export default SuccessPanel
