// extracted by mini-css-extract-plugin
export var bodyBase = "player-merge-module--body-base--1lMBa player-merge-module--site-font--cIHkL";
export var bodyLarge = "player-merge-module--body-large--p9Ie6 player-merge-module--body-base--1lMBa player-merge-module--site-font--cIHkL";
export var bodyLargeBold = "player-merge-module--body-large-bold--woEry player-merge-module--body-base--1lMBa player-merge-module--site-font--cIHkL";
export var bodyRegular = "player-merge-module--body-regular--43Xrk player-merge-module--body-base--1lMBa player-merge-module--site-font--cIHkL";
export var bodyRegularBold = "player-merge-module--body-regular-bold--kxRbX player-merge-module--body-base--1lMBa player-merge-module--site-font--cIHkL";
export var bodySmall = "player-merge-module--body-small--vr-cB player-merge-module--body-base--1lMBa player-merge-module--site-font--cIHkL";
export var bodySmallBold = "player-merge-module--body-small-bold--CsVK5 player-merge-module--body-base--1lMBa player-merge-module--site-font--cIHkL";
export var borderTop = "player-merge-module--border-top--Raaj1";
export var breakWordContainer = "player-merge-module--break-word-container--HOeO2";
export var buttonIconBase = "player-merge-module--button-icon-base--4Y8-Q";
export var clickLink = "player-merge-module--click-link--S8LQJ";
export var container = "player-merge-module--container--+cA8s";
export var dropdownBase = "player-merge-module--dropdown-base--+IxZm";
export var dropdownSelectBase = "player-merge-module--dropdown-select-base--tMHY- player-merge-module--text-input--3NQpu";
export var flexCol = "player-merge-module--flex-col--os7Hz";
export var formErrorMessage = "player-merge-module--form-error-message--Yzseh";
export var h3 = "player-merge-module--h3--xmQI5";
export var h4 = "player-merge-module--h4--Fka3R";
export var hoverLink = "player-merge-module--hover-link--eJoY-";
export var hoverRow = "player-merge-module--hover-row--9TghX";
export var linksContainer = "player-merge-module--linksContainer--+XIMh";
export var membershipContainer = "player-merge-module--membership-container---aui0 player-merge-module--flex-col--os7Hz player-merge-module--primary-border--5HF2s";
export var membershipHeader = "player-merge-module--membership-header--9PVTK";
export var membershipHeading = "player-merge-module--membership-heading--kWKQJ";
export var membershipLabel = "player-merge-module--membership-label--kMttE";
export var mergeBtn = "player-merge-module--mergeBtn--XSQ7c";
export var moreFiltersBorderClass = "player-merge-module--more-filters-border-class--GH7gz";
export var outerContainer = "player-merge-module--outerContainer--VZOh4";
export var pageBg = "player-merge-module--page-bg--GXlS8";
export var pointer = "player-merge-module--pointer--Tg6Z5";
export var primaryBorder = "player-merge-module--primary-border--5HF2s";
export var shadowBoxLight = "player-merge-module--shadow-box-light--glQZd";
export var siteFont = "player-merge-module--site-font--cIHkL";
export var slideDownAndFade = "player-merge-module--slideDownAndFade--+1cnX";
export var slideLeftAndFade = "player-merge-module--slideLeftAndFade--WDeGF";
export var slideRightAndFade = "player-merge-module--slideRightAndFade---VQCS";
export var slideUpAndFade = "player-merge-module--slideUpAndFade--jt0rj";
export var statusDecoration = "player-merge-module--status-decoration--MtfOz";
export var textInput = "player-merge-module--text-input--3NQpu";
export var textInverted = "player-merge-module--text-inverted--MbWjC";
export var textMediumDark = "player-merge-module--text-medium-dark--8kBb3";
export var tooltipFont = "player-merge-module--tooltipFont--1CaQs";
export var transferCard = "player-merge-module--transferCard--NovMq";
export var unstyledButton = "player-merge-module--unstyled-button--6SQzM";