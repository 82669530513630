import React, { useCallback, useState } from 'react'
import Search from '../filter-bar/filters/search/search'
import Button, { ButtonProps } from '../button/button'
import { ApolloError, DocumentNode, LazyQueryHookOptions, useLazyQuery } from '@apollo/client'
import * as styles from './find-or-clear.module.less'
import cx from 'classnames'
import { Grid } from '@material-ui/core'
import { ClassNamesProps, PropsFrom } from 'src/types'
import { useMergeClassMap } from 'src/hooks/use-merge-class-map'

type ClassKeys = 'container' | 'label' | 'btnFind' | 'btnClear' | 'foundText' | 'notFoundText'
type OverridableBtnProps = Omit<ButtonProps, 'onClick'>

export interface Props<TData, TSearchResult> extends ClassNamesProps<ClassKeys> {
  query: DocumentNode
  queryOptions?: LazyQueryHookOptions
  inputId: string
  label?: string
  btnFindText: string
  btnFindProps?: OverridableBtnProps
  btnClearText: string
  btnClearProps?: OverridableBtnProps
  notFoundText: string
  initialSearch?: string
  placeholderSearch?: string
  foundText: string | ((data: TData) => string)
  onSearch?: (search: string) => void
  onSearchError?: (error: ApolloError) => void
  /**
   * This gets called whenever the search query is successful.
   * @param data The data returned by the query if successful
   */
  onSearchCompleted?: (data: TData) => void
  /**
   * When a search query is successful, `getResultFromData` is called on the data and if it returns a non-null result, this function is called.
   * @param data The data returned by the query if successful
   */
  onSearchSuccess: (data: TData) => void
  onClear?: (data: TData) => void
  mapSearchToQueryVariables: (search: string) => Record<string, unknown>
  /**
   * If this returns `null` the component will show that no result has been found. You can implement custom logic to discard certain results returned by the query, for example:
   * ```
   *  return data.item.type === "PLAYER" ? data.item : null
   * ```
   * @param data The data returned by the query if successful
   */
  getResultFromData: (data: TData) => TSearchResult | null
  classNamesSearch?: PropsFrom<typeof Search>['classNames']
}

const FindOrClear = <TData, TSearchResult>({
  query,
  queryOptions,
  inputId,
  label,
  btnFindText,
  btnFindProps,
  btnClearText,
  btnClearProps,
  notFoundText,
  initialSearch,
  placeholderSearch,
  foundText,
  onSearch,
  onSearchError,
  onSearchCompleted,
  onSearchSuccess,
  onClear,
  mapSearchToQueryVariables,
  getResultFromData,
  classNames,
  classNamesSearch
}: Props<TData, TSearchResult>) => {
  const [search, setSearch] = useState(initialSearch || '')
  const [showNotFoundError, setShowNotFoundError] = useState(false)
  const [result, setResult] = useState<TSearchResult | null>(null)
  const [fetch, { data, loading }] = useLazyQuery<TData>(query, {
    ...queryOptions,
    onCompleted: data => {
      if (data) {
        const searchResult = getResultFromData(data)
        setResult(searchResult)
        setShowNotFoundError(!searchResult)
        searchResult && onSearchSuccess(data)
      }

      onSearchCompleted?.(data)
    },
    onError: onSearchError
  })

  const classesSearch = useMergeClassMap(classNamesSearch, {
    wrapper: styles.inputWrapper,
    input: [styles.input, { ['--has-error']: showNotFoundError }]
  })

  const handleSearch = useCallback(() => {
    fetch({ variables: mapSearchToQueryVariables(search) })
    onSearch?.(search)
  }, [onSearch, search, fetch, mapSearchToQueryVariables])

  const handleClear = useCallback(() => {
    setResult(null)
    setShowNotFoundError(false)
    onClear?.(data as TData)
  }, [onClear, data])

  const handleSearchFieldValueChange = useCallback((value: string) => {
    setShowNotFoundError(false)
    setSearch(value)
  }, [])

  return (
    <div className={cx(styles.container, classNames?.container)}>
      {label && (
        <label htmlFor={inputId} className={cx(styles.label, classNames?.label)}>
          {label}
        </label>
      )}
      {result ? (
        <Grid container>
          <p className={cx(styles.foundText, classNames?.foundText)}>
            {typeof foundText === 'function' ? foundText(data as TData) : foundText}
          </p>
          <Button
            onClick={handleClear}
            linkStyle
            className={cx(styles.btnClear, classNames?.btnClear)}
            {...(btnClearProps || {})}
          >
            {btnClearText}
          </Button>
        </Grid>
      ) : (
        <>
          <Grid container>
            <Search
              id={inputId}
              value={search}
              onValueChange={handleSearchFieldValueChange}
              placeholder={placeholderSearch}
              disableClearBtn
              classNames={classesSearch}
            />
            <Button
              onClick={handleSearch}
              loading={loading}
              level="secondary"
              className={cx(classNames?.btnFind)}
              {...(btnFindProps || {})}
            >
              {btnFindText}
            </Button>
          </Grid>
          {showNotFoundError && (
            <span role="note" className={cx(styles.notFoundText, classNames?.notFoundText)}>
              {notFoundText}
            </span>
          )}
        </>
      )}
    </div>
  )
}

export default FindOrClear
