import React, { useCallback, useState } from 'react'
import FindOrClear, { Props as FindOrClearProps } from '../find-or-clear/find-or-clear'
import { TFunction, useTranslation } from 'react-i18next'
import PageHeader from '../page-header/page-header'
import { Body } from '../typography/typography'
import {
  RecordTransferCard,
  Props as RecordTransferCardProps
} from '../record-transfer-card/record-transfer-card'
import { Grid } from '@material-ui/core'
import * as styles from './player-merge.module.less'
import Button from '../button/button'
import PlayerMergeModal, {
  Props as MergeModalProps
} from '../player-merge-modal/player-merge-modal'
import { DocumentNode, OperationVariables, useMutation, ApolloError } from '@apollo/client'
import { toast } from 'react-toastify'
import SuccessPanel from '../success-panel/success-panel'
import { RouterLink } from '../button/link'
import { PropsFrom } from 'src/types'

type SearchConfig<TData, TRecord, TSearchResult> = Omit<
  FindOrClearProps<TData, TSearchResult>,
  | 'onSearch'
  | 'onSearchError'
  | 'onSearchCompleted'
  | 'onSearchSuccess'
  | 'inputId'
  | 'notFoundText'
  | 'label'
  | 'btnFindText'
  | 'btnClearText'
> & {
  transformDataIntoRecord: (data: TData) => TRecord | null
}

type OverridableTransferCardProps = Omit<RecordTransferCardProps, 'recordType'>
type OverridableModalProps = Omit<
  MergeModalProps,
  'isVisible' | 'setIsVisible' | 'onConfirm' | 'isMerging'
>

interface ContextHelpers {
  helpers: {
    t: TFunction
  }
}

interface TransferCardContext<TRecord> extends ContextHelpers {
  record: TRecord
}

interface MergeRecords<T> {
  moving: T
  destination: T
}

interface MergeContext<TRecord> extends ContextHelpers {
  records: MergeRecords<TRecord>
}

interface TransferCardConfig<TRecord> {
  mapTransferCardProps: (context: TransferCardContext<TRecord>) => OverridableTransferCardProps
}

interface MergeConfig<TRecord> {
  mutation: DocumentNode
  mutationOptions?: OperationVariables
  mapContextToMutationVariables: (context: MergeContext<TRecord>) => Record<string, unknown>
  mapModalProps: (context: MergeContext<TRecord>) => OverridableModalProps
  mergeSuccessText?: (context: MergeContext<TRecord>) => string
  mergeSuccessLinks?: (
    helpers: ContextHelpers
  ) => { toUrl: string; text: string; level?: PropsFrom<typeof RouterLink>['level'] }[]
}

interface Props<TData, TRecord, TSearchResult> {
  searchConfig: SearchConfig<TData, TRecord, TSearchResult>
  transferCardConfig: TransferCardConfig<TRecord>
  mergeConfig: MergeConfig<TRecord>
}

const PlayerMerge = <TData, TRecord, TSearchResult>({
  searchConfig,
  transferCardConfig,
  mergeConfig
}: Props<TData, TRecord, TSearchResult>) => {
  const [mergeRecords, setMergeRecords] = useState<MergeRecords<TRecord | null>>({
    moving: null,
    destination: null
  })
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isMergeComplete, setIsMergeComplete] = useState(false)
  const { t } = useTranslation()
  const handleSearchError = useCallback(() => {
    toast.error(t('notifications.search.errorPlayerSearch'))
  }, [t])

  const [merge, { loading }] = useMutation(mergeConfig.mutation, {
    ...mergeConfig.mutationOptions,
    onError: (error: ApolloError) => {
      toast.dismiss()
      toast.error(error?.message ?? t('notifications.merge.error'))
    },
    onCompleted: () => {
      toast.dismiss()
      setIsModalVisible(false)
      setIsMergeComplete(true)
    }
  })

  if (isMergeComplete)
    return (
      <SuccessPanel
        title={t('mergePlayers.successScreen.header')}
        infoText={
          mergeRecords.moving &&
          mergeRecords.destination &&
          mergeConfig?.mergeSuccessText?.({
            records: mergeRecords as MergeRecords<TRecord>,
            helpers: { t }
          })
        }
      >
        <div className={styles.linksContainer}>
          {mergeConfig
            ?.mergeSuccessLinks?.({
              helpers: { t }
            })
            ?.map(link => (
              <RouterLink key={link.toUrl} to={link.toUrl} level={link.level ?? 'secondary'}>
                {link.text}
              </RouterLink>
            ))}
          <Button
            level={'primary'}
            onClick={() => {
              setMergeRecords({ moving: null, destination: null })
              setIsMergeComplete(false)
            }}
          >
            {t('merge players')}
          </Button>
        </div>
      </SuccessPanel>
    )

  return (
    <>
      <PageHeader title={t('mergePlayers.pageTitle')} noPadding />
      <Body size="lg" spacing={{ base: 4, margins: { sm: 'top', mdl: 'bottom' } }}>
        {t('mergePlayers.pageSubtitle')}
      </Body>
      <Grid container wrap="nowrap" className={styles.outerContainer}>
        <Grid className={styles.container}>
          <FindOrClear
            {...searchConfig}
            label={t('mergePlayers.search.label.1')}
            btnClearText={t('mergePlayers.search.button.clear')}
            btnFindText={t('mergePlayers.search.button.find')}
            notFoundText={t('mergePlayers.search.error.notFound')}
            inputId="merge-player-1"
            onSearchSuccess={data =>
              setMergeRecords(prev => ({
                ...prev,
                moving: searchConfig.transformDataIntoRecord(data)
              }))
            }
            onSearchError={handleSearchError}
            onClear={() => setMergeRecords(prev => ({ ...prev, moving: null }))}
          />
          {mergeRecords.moving && (
            <RecordTransferCard
              {...transferCardConfig.mapTransferCardProps({
                record: mergeRecords.moving,
                helpers: { t }
              })}
              recordType={'moving'}
              classNames={{ container: styles.transferCard }}
            />
          )}
        </Grid>
        <Grid className={styles.container}>
          <FindOrClear
            {...searchConfig}
            label={t('mergePlayers.search.label.2')}
            btnClearText={t('mergePlayers.search.button.clear')}
            btnFindText={t('mergePlayers.search.button.find')}
            notFoundText={t('mergePlayers.search.error.notFound')}
            inputId="merge-player-2"
            onSearchSuccess={data =>
              setMergeRecords(prev => ({
                ...prev,
                destination: searchConfig.transformDataIntoRecord(data)
              }))
            }
            onSearchError={handleSearchError}
            onClear={() => setMergeRecords(prev => ({ ...prev, destination: null }))}
          />
          {mergeRecords.destination && (
            <RecordTransferCard
              {...transferCardConfig.mapTransferCardProps({
                record: mergeRecords.destination,
                helpers: { t }
              })}
              recordType={'destination'}
              classNames={{ container: styles.transferCard }}
            />
          )}
        </Grid>
      </Grid>
      <Button
        className={styles.mergeBtn}
        disabled={!mergeRecords.moving || !mergeRecords.destination}
        onClick={() => setIsModalVisible(true)}
      >
        {t('mergePlayers.mergeBtn')}
      </Button>
      {mergeRecords.moving && mergeRecords.destination && (
        <PlayerMergeModal
          {...mergeConfig.mapModalProps({
            records: mergeRecords as MergeRecords<TRecord>,
            helpers: { t }
          })}
          isVisible={isModalVisible}
          setIsVisible={setIsModalVisible}
          onConfirm={() => {
            merge({
              variables: mergeConfig.mapContextToMutationVariables({
                records: mergeRecords as MergeRecords<TRecord>,
                helpers: { t }
              })
            })
            toast.loading(t('notifications.merge.loading'), {
              autoClose: false,
              closeButton: false,
              type: 'info'
            })
          }}
          isMerging={loading}
        />
      )}
    </>
  )
}

export default PlayerMerge
